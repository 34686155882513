.main-div {
  background-color: #ECF8FF;
  width: 100%;
  height: 100%
}

.headfont {
  margin-top: 10px;
  font-family: 'Inter';
  font-style: normal;
  font-size: 24px;
  line-height: 29px;
  text-transform: uppercase;
  font-weight: bold;
  color: #455A64;
}

.span1 {
  margin-top: 10px;
  width: 360px;
  height: 192px;
  margin-right: 20%;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #455A64;
}

.span2 {
  margin-top: 10px;
  width: 350px;
  height: 192px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #455A64;
}

.span3 {
  margin-top: 10px;
  width: 300px;
  height: 192px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #455A64;
}

.headfont1 {
  margin-top: 10px;
  font-family: 'Inter';
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #455A64;
  font-weight: bold;
}

.font1 {
  font-family: 'Inter';
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: #455A64;
  margin-bottom: 50px;
}

.servicediv {
  background-color: rgb(223, 22, 22)
}

.service {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
}

.service-help {
  text-align: center !important;

}

.help {
  color: #263238;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  text-transform: uppercase;
}

.needs {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-right: 15% !important;
  text-align: center;
  text-transform: uppercase;
}

.razor {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
  text-transform: uppercase;
}

.bottomdot1 {
  padding-inline: 5%;
}

.sidedot1 {
  padding-bottom: 80px;
}

.parent2 {
  position: absolute;
  margin-top: 0px;
}

@media only screen and (min-width: 370px) {
  .parent2 {
    position: absolute;
  }
}

.parent3 {
  position: absolute;
  margin-top: 0px;
}

@media only screen and (min-width: 370px) {
  .parent3 {

    position: absolute;
    margin-top: 0px;
    margin-left: 50px;
  }
}