.eterna {
    text-align: center !important;
    margin-top: 50px;
}

/* .title90 {
    text-align: center !important;

} */
.testmonialheader {
    background-color: #F7F7F7;
    /* background-color:red; */
}

.testmonial {
    /* margin-top: 100px; */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-transform: uppercase;
    color: rgba(38, 50, 56, 0.7);
    /* background-color:white; */
}

.testmonial h1 {
    color: black
}

.testmonialheader .casecaro {
    background-color: #F7F7F7;
}

.client {
    margin-top: 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    text-transform: uppercase;
    color: #263238;
}


.card-text3 {
    margin-top: 20px !important;
}


