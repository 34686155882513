@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;400&display=swap');

.Services {
  font-family: "Inter";
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.image {
  margin-left: 10px;
  margin-top: 20px;
  display: flex;
}

.content {
  font-size: 30px;
  font-family: "Inter";
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
}

.RQtitle1 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #263238;
  margin-top: 10px;
}

.cardbody {
  background-color: #ECF8FF;
  padding: 1.5rem 4rem !important;
}

.mydark {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
 
}

.parent1 {
  position: absolute;
  margin-top: 0px;
  
}

@media only screen and (min-width: 370px) {
  .parent1 {

    position: absolute;
    margin-top: 0px;
    margin-left: 50px;
  }
}
.myparent{
  margin-left: 48%;
}
@media(max-width:1000px) {
  .myparent {
    position: absolute;
    margin-left: 1%;
  }
}

.item {
  width: 80% !important;
  position: absolute;
  margin-top: 10%;
  margin-left: 5%;
}

.dotted {
  padding-inline: 90px;
}

.parent2 {
  position: absolute;
  margin-top: 0px;
}

@media only screen and (min-width: 370px) {
  .parent2 {

    position: absolute;
    margin-top: 0px;
    margin-right: 150px;
  }
}

.card-text1 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 27.6058px;
  line-height: 33px;
  text-transform: uppercase;
  color: #263238;
}