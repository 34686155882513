.casestudies {
  background-color: white;
  text-transform: uppercase;
  color: rgba(181, 216, 255, 0.5);
}

.casestudies h1 {
  font-size: 70px;
  text-align: end;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
}

h5 h1 h4 button label p {
  font-family: "Inter";
}

.leader {
  text-transform: uppercase;
  background-color: #ecf8ff;
}

.leaderheader {
  text-align: center;
  padding: 20px;
  font-family: "Inter";
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  text-transform: uppercase;
}

.header {
  margin: 10px;
}

.cards {
  flex-direction: row !important;
  justify-content: space-around !important;
}

.cardstyle {
  margin: 10 !important;
  width: 20rem;
  text-align: center;
}

.imgcontant {
  background-color: red;
  flex-direction: row !important;
  justify-content: center !important;
  margin: 20px !important;
}

.leadercontant {
  padding: 20px;
  margin-top: 40px;
}

.leadercontant h1 {
  font-size: 32px;
  text-align: start;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
}

.leadercontant li {
  font-size: 20px;
  text-align: start;
  font-family: "Inter";
  font-style: normal;
}

.leaderlist {
  margin-top: 20px !important;
  text-transform: none;
}

.leaderbtn {
  margin-top: 50px !important;
  display: flex;
  justify-content: start;
  padding-bottom: 10px;
}

.casestudybtn {
  margin-top: 5px !important;
  padding-left: 20px;
  padding-bottom: 10px;
  text-align: end;
}

.subcasebtn {
  margin-top: 30px !important;
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
}

.imgstyle {
  margin-top: 20px;
  width: 25rem;
}

.cardinsidestyle {
  margin: 15px;
  padding: 20px;
  width: 15rem;
}

.cardimg {
  width: 10%;
  height: 10%;
}

.techno {
  margin: 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  color: rgba(38, 50, 56, 0.7);
}

.techno h1 {
  color: black;
}

.techstyle {
  width: 10rem;
}

.techstylename {
  width: 10rem;
  text-align: center;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.holebtn {
  margin-top: 50px;
  padding-left: 80px;
}

.techstyle label {
  font-size: 15px;
  font-weight: 600;
}

.techstylename button {
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  border: none;
  background-color: white;
  width: 80%;
  height: 90%;
}

.techstylecontant {
  margin-top: 10px !important;
}

.casestudy2 {
  margin-top: 100px;
  text-transform: uppercase;
  color: rgba(181, 216, 255, 0.5);
  font-family: "Inter";
  text-align: center;
}

.casestudy2 h1 {
  font-size: 70px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
}

.cardstyle2 {
  width: 50rem;
}

.casestudyheader {
  margin-top: 30px !important;
}

.casestudyheader h1 {
  font-family: "Inter";
  text-align: center;
  font-size: 36px;
}

.footer {
  background-color: #ecf8ff;
}

.footerheader {
  padding-top: 20px;
  padding-bottom: 20px;
}

label {
  font-size: 20px;
}

.clockstyle {
  margin-top: 120px;
  text-align: center;
}

.clockname {
  margin-top: 10px;
  text-align: center;
}

.techcarousel {
  margin-top: 50px;
  padding: 10px;
}

.techcarousel1 {
  margin-top: 50px;
  /* padding: 10px; */
  /* padding-left: 300px !important; */
}

.techstack {
  background-color: white;
  padding-inline: 50px;
}

.bottomdot {
  padding-inline: 50px;
}

.casecaro {
  margin-left: 20px !important;
}

/* @media screen and (max-width: 800px) {
  .casecaro {
    margin-left: 90px !important;
  }
} */
.cards {
  flex-direction: row !important;
  justify-content: space-around !important;
}
.cardimgmainpage {
  /* margin-left: 12%; */
  width: 70%;
  height: 70%;
}

.cardstyle {
  /* margin: 10 !important; */
  background-color: white;
  width: 20rem;
  text-align: center;
  margin-left: 10%;
}

.contactcardstyle {
  /* margin-left: 60px; */
  width: 15rem;
}

.casestudybtn {
  background-color: white;
  margin-top: 5px !important;
  padding-left: 20px;
  padding-bottom: 10px;
  text-align: end;
}

.leaderfirst {
  width: 17rem;
}

.imgcard {
  background-color: red;
  justify-content: space-around;
}

.leadercontantss {
  text-align: start;
  /* padding-right: 100px; */
  /* padding: 10px; */
}

.subleader {
  text-align: start;
}

.leadercontantss p {
  font-size: 18px;
  font-family: "Inter";
  font-style: normal;
  line-height: 30px;
  text-transform: none;
}
/* @media (max-width:1000px) {
    .leadercontantss .container{
      font-size: 28px;
    }
  } */
.cardicon {
  padding: 10px;
  text-align: end;
}

.subcontants {
  margin-top: 20px;
  margin-bottom: 50px;
}
