@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;400&display=swap');

.ourtitle {
  font-family: 'Inter';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  color: #263238;

}

.paras {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  text-transform: capitalize;
  color: #263238;
}

.imgstyle1 {
  margin-top: 20px;
  width: 25rem;

}

.test {
  background-color: black;
}

.vertical-center {
  min-height: 100%;
  min-height: 50vh;
  display: flex;
  align-items: center;
}

.card-body {
  margin-top: 50px;
}

.Title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-transform: uppercase;
  color: #263238;
  margin-top: 50px;
}

.title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: start;
  text-transform: uppercase;
  color: #263238;

}



@media screen and (max-width:800px) {
  .CareerBtn{
   text-align: center;
  }
}
