.background {
    background: linear-gradient(107.23deg, #F4F9FB -0.36%, #F4F9FB 100%);
}

.termpara {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000
}

.headingterms {
    font-weight: bold;
    font-family: 'Inter';
    font-style: normal;
    font-size: 20px;
}