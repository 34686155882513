@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;400&display=swap');

.main {
    background-color: aquamarine;
}

.main-div {
    background-color: #ECF8FF;
    width: 100%;
    height: 100%
}

.headfont {
    font-family: 'Inter';
    font-style: normal;
    font-size: 24px;
    line-height: 29px;
    text-transform: uppercase;
    font-weight: bold;
    color: #455A64;
}

.span1 {
    margin-top: 10px;
    width: 360px;
    height: 192px;
    margin-right: 20%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #455A64;
}

.span2 {
    margin-top: 10px;
    width: 350px;
    height: 192px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #455A64;
}

.span3 {
    margin-top: 10px;
    width: 300px;
    height: 192px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #455A64;
}

.headfont1 {
    font-family: 'Inter';
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    color: #455A64;
    font-weight: bold;
}

.font1 {
    font-family: 'Inter';
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    color: #455A64;
    margin-bottom: 50px;
}

.servicediv {
    background-color: white
}

.service {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-transform: uppercase;
}

.service-help {
    text-align: center !important;

}

.help {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    text-transform: uppercase;
}

.needs {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
}

.imga {
    background: url(../src/assets/rectangle.png);
    background-repeat: no-repeat;
    width: 80%;
}

.razor {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    text-transform: uppercase;
}

.title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    text-align: start;
    text-transform: uppercase;
    color: #263238;
}

.career {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: flex-end;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF
}

.card-title {
    text-align: start;
}