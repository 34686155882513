.clockstyle {
    margin-top: 120px;
    text-align: center;
}

.clockname {
    margin-top: 10px;
    text-align: center;
}

.clockmystyle {
    background-color: white;
}