@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400&family=Roboto:wght@100;300&display=swap');
.career1{
  color: #B5D8FF;
  text-transform: uppercase;
  font-size: 80px;
  font-family: inter;
  font-weight: 600;
  padding-top: 30px;
}
.career-info{
  font-size: 16px;
  font-family: roboto;
  margin-top: 10px;
}
.grp-img{
  width: 25rem;
}
.card2{
  width: 320px;
  border-radius: 10px;
  box-shadow: 0 4px 3px rgba(0, 0, 0, 0.12), 0 5px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.1s cubic-bezier(0.25, 0.8, 0.25, 1);
  margin: 1rem;
  height: 220px;
}
.card1{
  width: 400px;
  border-radius: 10px;
  box-shadow: 0 4px 3px rgba(0, 0, 0, 0.12), 0 5px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.1s cubic-bezier(0.25, 0.8, 0.25, 1);
  margin: 1rem;
}
.apply_btn{
  background-color: #263238;
  width: 110px;
  color: #fff;
  border-radius: 2px;
  padding: 0.5rem;
  font-size: 14px;
  font-family: roboto;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  transition: all 0.1s cubic-bezier(0.25, 0.8, 0.25, 1);
}