@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
}

footer {
  width: 100%;
  /* position: fixed; */
  bottom: 0;
  left: 0;
  background: linear-gradient(107.23deg, #F4F9FB -0.36%, #F4F9FB 100%);

}

footer .content {
  max-width: 1350px;
  margin: auto;
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  /* text-align: center; */

}

footer .content p,
a {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* or 169% */
  color: #455A64;
}

footer .content .box {
  width: 25%;
  transition: all 0.4s ease;
}

footer .content .topic {
  font-size: 22px;
  font-weight: 600;
  color: #263238;
  /* margin-bottom: 16px; */

}

footer .content p {
  text-align: justify;
}

footer .content .lower .topic {
  margin: 24px 0 5px 0;
}

footer .content .lower i {
  padding-right: 16px;
}

footer .content .middle {
  padding-left: 10%;
}

footer .content .middle a {
  line-height: 32px;
}

footer .content .right {
  padding-left: 20px;
}


footer .content .media-icons a {
  font-size: 16px;
  height: 45px;
  width: 45px;
  display: inline-block;
  text-align: center;
  line-height: 43px;
  border-radius: 5px;
  border: 2px solid #222222;
  margin: 30px 5px 0 0;
  transition: all 0.3s ease;
}

footer .bottom {
  width: 100%;
  text-align: center;
  color: #455A64B2;
  ;
  padding: 0 40px 5px 0;

}

footer .bottom a {
  color: #455A64B2;
}

footer a {
  transition: all 0.3s ease;
  text-decoration: none;
  color: #455A64;
}

@media (max-width:1100px) {
  footer .content .middle {
    padding-left: 20px;
  }
}

@media (max-width:950px) {
  footer .content .box {
    width: 50%;
  }

  .content .right {
    margin-top: 40px;
  }
}

@media (max-width:560px) {
  footer {
    position: relative;
  }

  footer .content .box {
    width: 100%;
    margin-top: 30px;
  }
}

#footerheading {
  text-align: center;
  justify-content: center;
  padding: 50px;
}

.emailheading {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size:  5vw;
  line-height: 97px;
  text-align: center;
  /* identical to box height */
  background: linear-gradient(90deg, #1755CE 13.46%, #029EFF 86.28%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
@media (max-width:1000px) {
  .emailheading{
    font-size: 28px;
  }
}


.heading {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  /* identical to box height */

  text-align: center;
  text-transform: uppercase;

  color: #1755CE;
}

.rqb {
  padding: 10px;
}

#whatsappimage {

  padding-top: 30%;

}
.img-fluid {
  max-width: 250px;
}

.parainfo {
  margin-top: 10px !important;
}

.upper {
  padding-left: 20px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
.parafooter{
  margin-top: 20px;
}