.contact_us {
  text-transform: uppercase;
  color: rgba(181, 216, 255, 0.5);
    margin:  20px 0px -34px 0px;
}

.contact_us h1 {
  font-size: 70px;
  text-align: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
}
.text-white{
  margin-top: 280px;
}
.contactformmain {
  background: linear-gradient(107.23deg, #F4F9FB -0.36%, #F4F9FB 100%);
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;500&family=Lobster&family=Roboto:ital,wght@0,100;0,500;1,300&display=swap');

body {
  background: linear-gradient(107.23deg, #F4F9FB -0.36%, #F4F9FB 100%);
}

label {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #717171;
  padding-top: 15px;

}


#textaream {
  border: none;
  border-bottom: 3px solid rgb(212, 212, 212);
}

.contactushadding {
  position: absolute;
  left: 33.22%;
  right: 34.63%;
  top: 8.90%;
  bottom: 81.22%;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 100px;
  line-height: 121px;
  text-align: center;
  text-transform: uppercase;
  color: rgba(181, 216, 255, 0.5);
}

#pp-container {
  box-sizing: border-box;
  border: 1px solid #DADADA;
  border-radius: 33px;
  margin: auto;
  padding: 15px;
}

.contactform {
  background-color: white;
  padding: 30px;
  border-radius: 5%;
}

#ptag {
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  padding: 30px;
  text-align: center;
  justify-content: center;
}

#inputbox {
  border: none;
  border-bottom: 3px solid rgb(212, 212, 212);
  width: 90%;
  align-items: center;
  padding: 15px;
}

#Pivacyhadding {
  font-size: 100px;
  font-family: 'Inter';
  font-weight: 600;
  text-align: center;
  line-height: 121px;
  font-style: normal;
  color: rgba(181, 216, 255, 0.5);
  justify-content: center;
  margin: auto;

}


#h2tag {
  font-size: 5rem;
  text-align: center;
  justify-content: center;
  font-weight: 600;
  line-height: 121px;
  color: rgba(181, 216, 255, 0.5);

}

@media screen and (min-width: 601px) {
  div #h2tag {
    font-size: 5rem;
    font-family: 'Inter';
    font-weight: 600;
    text-align: center;
    line-height: 121px;
    font-style: normal;
    color: rgba(181, 216, 255, 0.5);
  }
}

#sbtn {
  width: 150px;
  height: 50px;
  font-size: 1rem;
}

#Team-hadding {
  margin: 50px 0px -45px 0px;

}