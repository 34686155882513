.headfont{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    text-transform: uppercase;
    color: #455A64
}
.subcasebtn1 {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
}
.togglecard{
    border: none;

}
.card{
    text-align: center !important;
}
.span3{
    font-weight: 600;
}
.span2{
    font-weight: 600;
}
/* .serv{
    background-color: "red";
} */
.product{
   padding-right:5%;
}
.product1{
    padding-right:7%;
}
.advisory{
    padding-right:10px;
}

.bigdata{
    padding-right:2%;
}


.blockchain{
    padding-left: 15% ;
}
@media (max-width:1000px) {
    .blockchain{
        padding-left: 0 ;
    }
  }

.omni{
    padding-right: 15%;
}
@media (max-width:1000px) {
    .omni{
        padding-right: 0;
    }
  }

.artificial{
    padding-right:1%;
}
.iot{
    padding-left: 15% ;
}
@media (max-width:1000px) {
    .iot{
        padding-left: 0 ;
    }
  }

  .arvr{
    padding-right: 15%; 
  }
  @media (max-width:1000px) {
    .arvr{
        padding-right: 0;
    }
  }
  .sercontent{
      text-align:start;
  }
  @media (max-width:1000px) {
    .sercontent{
      text-align: center;
    }
  }