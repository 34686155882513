
.policy {
    text-transform: uppercase;
    color: rgba(181, 216, 255, 0.5);
      margin:  20px 0px -35px 0px;

}

.policy h1 {
    font-size: 70px;
    text-align: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
}
.ptag{
    
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  padding: 30px;
  text-align: center;
  justify-content: center;
}
.link{
  color: SteelBlue;
}
