.vertical-center1 {
    min-height: 100%;  
    display: flex;
    align-items: center;
    padding: 30px;
  }
  .contcard{
    width: 400px;
    border-radius: 10px;
    box-shadow: 0 4px 3px rgba(0, 0, 0, 0.12), 0 5px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.1s cubic-bezier(0.25, 0.8, 0.25, 1);
    margin: 1rem;
  }


  @media screen and (max-width:800px) {
    .ContactBtn{
     text-align: center;
    }
  }
  