.Navbarstyle {
  border-radius: 2px;
  width: auto;
  box-shadow: 10px 10px 25px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;
  text-align: start;
}

.subdiv {
  margin-right: 10px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #455A64;
  margin-left: 2rem;
}

.logo {
  margin-left: 5px;
}

.main1 {
  background-color: red;
}

.main2 {
  background-color: green;
}

.rq {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  position: absolute;
}

.paratext {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 27.6058px;
  line-height: 33px;
  text-transform: uppercase;
  position: absolute;
}

.LanguageDropDown{
 border: none;

}
